<template>
  <div class="takeClothesIndexView content-index" v-loading="loading">
    <div class="topView">
      <el-page-header @back="goBack">
        <template slot="content">
          <div>
            衣物盘点
            <span>【盘点单号: {{ this.checkData.checkNumber }}】</span>
          </div>
        </template>
      </el-page-header>
    </div>
    <div class="searchView">
      <el-row style="text-align:right;padding-bottom:10px">
        <el-button type="primary" @click="delCheckList">移除衣物</el-button>
      </el-row>
      <el-row :gutter="20" style="height:100%">
        <el-col :span="10" style="height:100%">
          <div class="table-box" v-loading="tableLoading">
            <div class="table-title">待盘点衣物列表</div>
            <Table
              :serialNumber="true"
              :header-style="headerStyle"
              :orientation="'center'"
              :height="'calc(100vh - 260px)'"
              :page="true"
              :table-data="tableData1"
              :title-list="titleListData"
            />
          </div>
        </el-col>
        <el-col :span="4" class="center">
          <div style="display:flex;">
            <el-input
              placeholder="请扫描 / 输入条码号"
              ref="query"
              v-model.trim="clothesNum"
              style="margin-right: 10px"
              @keyup.enter.native="handleClothes"
            ></el-input>
            <el-button
              type="primary"
              :loading="handleClothesLoading"
              @click="handleClothes"
              >确认</el-button
            >
          </div>
          <div class="item" style="padding-top:40px">待盘点数量</div>
          <div class="item" style="padding-bottom:10px">
            <span style="font-size:16px;padding:0 2px">{{
              tableData1 && tableData1.length != 0 ? tableData1.length : 0
            }}</span
            >件
          </div>
          <div class="item">已扫码数量</div>
          <div class="item" style="color:#3370FF;padding-bottom:10px">
            <span style="font-size:16px;padding:0 2px">{{
              tableData2 && tableData2.length != 0 ? tableData2.length : 0
            }}</span
            >件
          </div>
          <div class="item">待处理异常衣物</div>
          <div class="item" style="color:#FF3333;padding-bottom:30px">
            <span style="font-size:16px;padding:0 2px">{{
              checkData.noHandleCount ? checkData.noHandleCount : 0
            }}</span
            >件
          </div>
          <div class="button">
            <el-button
              type="primary"
              :disabled="checkData.checkFlag != 1"
              @click="setConfirmCheck"
              ><span class="span1">①</span>确定盘点</el-button
            >
          </div>
          <div class="button">
            <el-button
              :disabled="checkData.checkFlag != 1"
              type="primary"
              @click="codeComplete"
              style="background-color: #FFB433"
              ><span class="span1">②</span>扫码完成</el-button
            >
          </div>
          <div class="button">
            <el-button
              type="primary"
              :disabled="checkData.checkFlag != 2"
              @click="disposeClothes"
              style="background-color: #FF3333"
              ><span class="span1">③</span>处理衣物({{
                checkData.noHandleCount ? checkData.noHandleCount : 0
              }}件)</el-button
            >
          </div>
          <div class="button">
            <el-button
              type="primary"
              :disabled="checkData.checkFlag != 3"
              @click="completeCheck"
              ><span class="span1">④</span>盘点完成</el-button
            >
          </div>
        </el-col>
        <el-col :span="10" style="height:100%">
          <div class="table-box">
            <div class="table-title">扫码衣物列表</div>
            <Table
              :serialNumber="true"
              :select="true"
              :header-style="headerStyle"
              :orientation="'center'"
              :height="'calc(100vh - 260px)'"
              :page="true"
              :table-data="tableData2"
              :title-list="titleListData"
              @selectChange="handleSelectionChange"
            />
          </div>
        </el-col>
      </el-row>
    </div>
    <!-- 衣物处理 -->
    <el-dialog
      :visible="disposeClothesDialogVisible"
      :show-close="false"
      :top="'10vh'"
      width="70%"
      center
    >
      <template slot="title">
        <div class="title">
          <div class="text">处理衣物</div>
          <span @click="closeProcessClothes" class="el-icon-close icon"></span>
        </div>
      </template>
      <div class="processClothes-table" v-loading="processClothesLoading">
        <Table
          :serialNumber="true"
          :header-style="headerStyle"
          :orientation="'center'"
          :page="true"
          :button-type="true"
          :table-data="processClothesTable"
          :title-list="titleListData2"
          @operationClick="processClothesOperationClick"
        />
      </div>
    </el-dialog>
    <!-- 销单 -->
    <el-dialog
      :visible="singleEliminationDialogVisible"
      :show-close="false"
      :top="'10vh'"
      width="50%"
      center
    >
      <template slot="title">
        <div class="title">
          <div class="text">销单</div>
        </div>
      </template>
      <el-form
        style="width:510px;margin:0 auto"
        :model="singleEliminationForm"
        :rules="singleEliminationRules"
        ref="singleEliminationForm"
        label-width="100px"
      >
        <el-form-item label="异常类型" prop="exceptionType">
          <el-select
            style="width:300px"
            v-model="singleEliminationForm.exceptionType"
            placeholder="请选择"
            @change="changeExceptionType"
          >
            <el-option
              v-for="item in exceptionTypeOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="修改状态" prop="updateStatus">
          <el-select
            style="width:300px"
            disabled
            v-model="singleEliminationForm.updateStatus"
            placeholder="请选择"
          >
            <el-option
              v-for="item in modificationTypeOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="标记"
          prop="markFlag"
          v-if="singleEliminationForm.exceptionType != 2"
        >
          <el-radio-group v-model="singleEliminationForm.markFlag">
            <el-radio :label="1">丢失</el-radio>
            <el-radio :label="2">忘记交给顾客</el-radio>
            <el-radio :label="3">其他门店衣物</el-radio>
            <el-radio :label="4">其他</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="特殊说明">
          <el-input
            style="width:300px"
            type="textarea"
            :autosize="{ minRows: 2, maxRows: 4 }"
            placeholder="请输入内容"
            v-model="singleEliminationForm.specialDescription"
          >
          </el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <div class="button-box">
          <el-button @click="closeSingleElimination">取 消</el-button>
          <el-button
            :loading="buttotnLoading"
            type="primary"
            @click="enterSingleElimination"
            >确 定</el-button
          >
        </div>
      </span>
    </el-dialog>
    <el-dialog
      class="custom-dialog"
      :visible="customDialogVisible"
      :top="'calc(50vh - 70px)'"
      width="420px"
      :show-close="false"
    >
      <template slot="title">
        <div class="title">
          <span style="font-size: 18px;">扫码成功</span>
          <span @click="closeCode" class="el-icon-close icon"></span>
        </div>
      </template>
      <div class="content">
        <span class="el-icon-error content-icon"></span>
        <span>确定已经把门店的衣物都扫码完成了吗？</span>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button size="medium" @click="closeCode">我再看看</el-button>
        <el-button
          size="medium"
          type="primary"
          :disabled="timeValue != 0"
          @click="enterCode"
          >确 定<span v-if="timeValue != 0">({{ timeValue }})</span></el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import Table from "@/components/table2";
import {
  getClothescheck,
  continueClothescheck,
  codeCheck,
  confirmCheck,
  codeFinished,
  queryHandleClothes,
  checkExplain,
  checkFinished,
} from "@/api/clothes/check";
export default {
  name: "index",
  components: {
    Table,
  },
  beforeRouteLeave(to, form, next) {
    if (this.tableData2.length != 0) {
      this.$confirm(
        "扫码衣物列表存在数据，是否留在本页面继续操作? 确定离开扫码衣物列表数据将会清空",
        "提示",
        {
          confirmButtonText: "确定离开",
          cancelButtonText: "我再看看",
          type: "warning",
        }
      )
        .then(() => {
          // this.setConfirmCheck();
          next();
        })
        .catch(() => {});
    } else {
      next();
    }
  },
  data() {
    return {
      customDialogVisible: false,
      loading: false,
      tableLoading: false,
      checkData: {},
      clothesNum: "",
      handleClothesLoading: false,
      headerStyle: {
        fontSize: "14px",
        color: "black",
      },
      tableData1: [],
      tableData2: [],
      titleListData: [
        {
          prop: "outClothesNum",
          label: "衣物号",
        },
        {
          prop: "clothesName",
          label: "衣物名称",
        },
        {
          prop: "clothesStatusDesc",
          label: "当前状态",
        },
      ],
      titleListData2: [
        {
          prop: "orderNumber",
          label: "订单号",
        },
        {
          prop: "outClothesNum",
          label: "衣物号",
        },
        {
          prop: "clothesName",
          label: "衣物名称",
        },
        {
          prop: "colorName",
          label: "衣物颜色",
        },
        {
          prop: "clothesStatusDesc",
          label: "当前状态",
        },
        {
          prop: "sendWashTime",
          label: "送洗时间",
        },
      ],
      processClothesTable: [],
      disposeClothesDialogVisible: false,
      singleEliminationDialogVisible: false,
      singleEliminationForm: {},
      singleEliminationRules: {
        exceptionType: [
          { required: true, message: "请选择异常类型", trigger: "change" },
        ],
        updateStatus: [
          { required: true, message: "请选择修改状态", trigger: "change" },
        ],
        markFlag: [
          { required: true, message: "请选择标记", trigger: "change" },
        ],
      },
      exceptionTypeOptions: [
        {
          value: 1,
          label: "衣物不在店,状态在店(衣物未被取走)",
        },
        {
          value: 2,
          label: "衣物不在店,状态在店(衣物已取走)",
        },
        {
          value: 3,
          label: "衣物在店,状态不在店",
        },
      ],
      modificationTypeOptions: [
        {
          value: 9,
          label: "已取衣",
        },
        {
          value: 13,
          label: "已退衣",
        },
      ],
      signleData: {},
      processClothesLoading: false,
      buttotnLoading: false,
      selectList: [],
      totalList: [],
      timeValue: 10,
      timeOut: null,
    };
  },
  watch: {
    $route: {
      handler: function(value) {
        this.checkId = value.query.checkId ? value.query.checkId : null;
      },
      deep: true,
      immediate: true,
    },
  },
  created() {
    this.listWaitToWash();
  },

  mounted() {},
  methods: {
    goBack() {
      this.$router.push("/home/more/clothinginventory");
    },
    // 获取衣物信息
    listWaitToWash() {
      if (this.checkId || this.checkData.checkId) {
        this.tableLoading = true;
        continueClothescheck({
          checkId: this.checkId || this.checkData.checkId,
        })
          .then(({ data }) => {
            if (data.code == 0) {
              this.checkData = data.data;
              if (
                this.checkData.checkFlag == 2 &&
                this.checkData.noHandleCount == 0
              ) {
                this.checkData.checkFlag = 3;
              }
              this.tableData1 = data.data.clothesCheckList;
              this.totalList = data.data.clothesCheckList;
              this.tableLoading = false;
            } else {
              this.checkData = {};
              this.tableData1 = [];
              this.tableLoading = false;
            }
          })
          .catch(() => {
            this.checkData = {};
            this.tableData1 = [];
            this.tableLoading = false;
          });
      } else {
        this.tableLoading = true;
        getClothescheck()
          .then(({ data }) => {
            if (data.code == 0) {
              this.checkData = data.data;
              this.tableData1 = data.data.clothesCheckList;
              this.totalList = data.data.clothesCheckList;
              this.tableLoading = false;
            } else {
              this.checkData = {};
              this.tableData1 = [];
              this.tableLoading = false;
            }
          })
          .catch(() => {
            this.checkData = {};
            this.tableData1 = [];
            this.tableLoading = false;
          });
      }
      this.$nextTick(() => {
        this.$refs.query.focus();
      });
    },
    // 获取衣物码
    handleClothes() {
      if (!this.clothesNum) {
        return this.$message.error("请输入衣物码");
      }
      let existence = this.tableData2.find((item) => {
        return item.outClothesNum === this.clothesNum;
      });
      if (existence) {
        this.$message.error("已经扫描过了");
        this.clothesNum = "";
        this.$nextTick(() => {
          this.$refs.query.focus();
        });
        this.audio.btts("重复扫描");
        return;
      }
      this.handleClothesLoading = true;
      codeCheck({ outClothesNum: this.clothesNum })
        .then(({ data }) => {
          if (data.code == 0) {
            this.tableData1 = this.tableData1?.filter(
              (item) => item.outClothesNum != data.data.outClothesNum
            );
            this.tableData2.unshift({ ...data.data });
            this.audio.btts(data.data.clothesName);
            this.handleClothesLoading = false;
            this.clothesNum = "";
            this.$nextTick(() => {
              this.$refs.query.focus();
            });
          } else {
            this.$message.error(data.msg);
            this.audio.btts(data.msg);
            this.handleClothesLoading = false;
          }
        })
        .catch(() => {
          this.handleClothesLoading = false;
        });
    },
    // 确定盘点
    setConfirmCheck() {
      if (this.tableData2.length != 0) {
        this.loading = true;
        let arr = this.tableData2.map((item) => {
          return item.outClothesNum;
        });
        confirmCheck(this.checkData.checkId, arr)
          .then(({ data }) => {
            if (data.code == 0) {
              this.loading = false;
              this.$message.success("操作成功");
              this.tableData2 = [];
            } else {
              this.loading = false;
            }
          })
          .catch(() => {
            this.loading = false;
          });
      } else {
        this.$message.error("扫码衣物列表不存在数据");
      }
    },
    // 扫码成功
    codeComplete() {
      if (this.tableData2.length == 0) {
        this.timeValue = 10;
        this.customDialogVisible = true;
        this.timeOut = setInterval(() => {
          this.timeValue = this.timeValue - 1;
          if (this.timeValue == 0) {
            clearTimeout(this.timeOut);
          }
        }, 1000);
      } else {
        this.$message.error("扫码衣物列表存在数据，请先确定盘点");
      }
    },
    closeCode() {
      this.customDialogVisible = false;
      this.timeValue = 10;
      clearTimeout(this.timeOut);
    },
    enterCode() {
      codeFinished({ checkId: this.checkData.checkId }).then(({ data }) => {
        if (data.code == 0) {
          this.checkData = data.data;
          if (this.checkData.noHandleCount == 0) {
            this.checkData.checkFlag = 3;
          }
          this.tableData1 = [];
          this.customDialogVisible = false;
          this.$message({
            type: "success",
            message: "操作成功!",
          });
        }
      });
    },
    // 处理衣物
    disposeClothes() {
      this.disposeClothesDialogVisible = true;
      this.processClothesLoading = true;
      queryHandleClothes({ checkId: this.checkData.checkId })
        .then(({ data }) => {
          if (data.code == 0) {
            this.processClothesTable = data.data.map((item) => {
              item.buttonList = [
                {
                  name: "销单",
                  size: "mini",
                  type: "primary",
                  code: "single",
                },
              ];
              return item;
            });
            this.processClothesLoading = false;
          } else {
            this.processClothesLoading = false;
          }
        })
        .catch(() => {
          this.processClothesLoading = false;
        });
    },
    // 盘点完成
    completeCheck() {
      this.$confirm(" 确定盘点完成, 发生的异常衣物将自动保存?", "盘点完成", {
        confirmButtonText: "确定完成",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          checkFinished({ checkId: this.checkData.checkId }).then(
            ({ data }) => {
              if (data.code == 0) {
                this.$message({
                  type: "success",
                  message: "盘点完成!",
                });
                this.$router.push("/home/more/clothinginventory");
              } else {
                this.$message.error(data.msg);
              }
            }
          );
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消操作",
          });
        });
    },
    // 点击处理衣物操作按钮触发
    processClothesOperationClick({ code, row }) {
      if (code == "single") {
        this.signleData = { ...row };
        if (this.signleData.clothesStatusDesc == "已退洗") {
          this.singleEliminationForm.updateStatus = 13;
        } else {
          this.singleEliminationForm.updateStatus = 9;
        }
        this.singleEliminationDialogVisible = true;
      }
    },
    // 关闭衣物处理弹框
    closeProcessClothes() {
      this.disposeClothesDialogVisible = false;
    },
    // 关闭销单
    closeSingleElimination() {
      this.singleEliminationDialogVisible = false;
      this.singleEliminationForm = {};
      this.$refs.singleEliminationForm.resetFields();
    },
    // 确认销单
    enterSingleElimination() {
      this.$refs.singleEliminationForm.validate((valid) => {
        if (valid) {
          this.buttotnLoading = true;
          checkExplain({
            checkId: this.checkData.checkId,
            clothesNum: this.signleData.clothesNum,
            ...this.singleEliminationForm,
          })
            .then(({ data }) => {
              if (data.code == 0) {
                this.$message({
                  type: "success",
                  message: "销单成功!",
                });
                this.processClothesTable = this.processClothesTable.filter(
                  (item) => item.clothesNum != this.signleData.clothesNum
                );
                this.checkData.noHandleCount = this.checkData.noHandleCount
                  ? this.checkData.noHandleCount - 1
                  : 0;
                if (this.processClothesTable.length == 0) {
                  this.checkData.checkFlag = 3;
                }
                this.closeSingleElimination();
                this.buttotnLoading = false;
              } else {
                this.closeSingleElimination();
                this.buttotnLoading = false;
              }
            })
            .catch(() => {
              this.closeSingleElimination();
              this.buttotnLoading = false;
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    // 修改异常类型
    changeExceptionType() {
      if (this.singleEliminationForm.exceptionType == 2) {
        this.singleEliminationForm = {
          ...this.singleEliminationForm,
          markFlag: null,
        };
      }
    },
    // 表格勾选
    handleSelectionChange( data) {
      this.selectList = data;
    },
    // 移除盘点衣物
    delCheckList() {
      if (this.selectList && this.selectList.length == 0) {
        this.$message.error("未选择衣物数据无法移除！");
      } else {
        this.selectList?.map((item) => {
          this.tableData2 = this.tableData2.filter((item2) => {
            if (item2.outClothesNum != item.outClothesNum) {
              return item2;
            }
          });
        });
        this.selectList?.map((item) => {
          let val = this.totalList.find((item2) => {
            if (item.outClothesNum == item2.outClothesNum) {
              return item;
            }
          });
          if (val) {
            this.tableData1.unshift(val);
          }
        });
      }
    },
  },
};
</script>

<style scoped lang="scss">
::v-deep input::-webkit-outer-spin-button,
::v-deep input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}
::v-deep input[type="number"]{
    -moz-appearance: textfield;
}
.takeClothesIndexView {
  position: relative;
  background: #fff;
  overflow: hidden;
  font-family: PingFangSC-Regular;
  .custom-dialog {
    ::v-deep .el-dialog {
      border-radius: 3px !important;
    }
    ::v-deep .el-dialog__header {
      padding: 0;
    }
    ::v-deep .el-dialog__body {
      padding: 0;
    }
    ::v-deep .el-dialog__footer {
      padding-bottom: 10px;
    }
    .title {
      display: flex;
      justify-content: space-between;
      padding: 15px 15px 10px;
      box-sizing: border-box;
      position: relative;
      .icon {
        position: absolute;
        top: 15px;
        right: 15px;
        background-color: #fff;
        color: #ccc;
        font-weight: 400;
      }
    }
    .content {
      padding: 10px 15px;
      .content-icon {
        color: red;
        font-size: 20px;
        vertical-align: middle;
        padding-right: 5px;
      }
    }
    .dialog-footer {
      padding: 5px 0 0;
    }
  }
  > .topView {
    padding: 17.5px 20px;
    overflow: hidden;
  }

  > .searchView {
    padding: 0 30px 0 30px;
    box-sizing: border-box;
    .table-box {
      border: 1px solid rgba(235, 238, 245, 100);
      .table-title {
        border-bottom: 1px solid rgba(235, 238, 245, 100);
        height: 50px;
        line-height: 50px;
        text-align: center;
        color: rgba(16, 16, 16, 100);
        font-weight: 700;
        font-size: 14px;
      }
    }
    .center {
      .item {
        text-align: center;
        padding: 5px 0px;
        color: rgba(16, 16, 16, 100);
        font-size: 14px;
      }
      .button {
        padding: 10px 0px;
        text-align: center;
        .el-button {
          border: none;
          .span1 {
            position: relative;
            top: -1px;
            left: -2px;
          }
        }
      }
    }
  }
  .title {
    display: flex;
    justify-content: center;
    position: relative;
    color: rgba(16, 16, 16, 100);
    font-size: 20px;
    width: 100%;
    .icon {
      position: absolute;
      top: 0;
      right: 0px;
      height: 22px;
      width: 22px;
      background-color: rgba(217, 217, 217, 100);
      color: #fff;
      border-radius: 50%;
      font-size: 16px;
      font-weight: 700;
      text-align: center;
      line-height: 22px;
    }
  }
  .processClothes-table {
    height: calc(100vh - 35vh);
    overflow: auto;
  }
  .button-box {
    width: 300px;
    display: flex;
    margin: 0 auto;
    justify-content: space-between;
    .el-button {
      width: 120px;
    }
  }
}
</style>
